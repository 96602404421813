import styled from 'styled-components';
import tw from 'twin.macro';

export const MenuWrapperStyle = styled.div`
  ${tw`grid grid-cols-1 gap-3 overflow-y-scroll lg:grid-cols-2 max-h-96`}
`;

export const OrderSectionStyles = styled.main`
  ${tw`container py-10 mx-auto`};

  form {
    ${tw`px-5 py-10 mx-auto bg-white rounded-md dark:bg-gray-800`}
  }
`;

export const FieldSetWrapperStyles = styled.div`
  &[disabled='true'] {
    ${tw`cursor-not-allowed disabled:opacity-50`}
  }
  ${tw`grid grid-cols-1 gap-6 sm:grid-cols-2 `};
  &.bill {
    ${tw`flex flex-wrap justify-end gap-0`}

    h3 {
      ${tw`flex-shrink-0 my-3 text-xl lg:text-2xl`}
      span {
        ${tw`font-bold text-red-600`}
      }
    }
    div {
      ${tw`flex justify-end flex-grow w-full`}
      button {
        &[disabled='true'] {
          ${tw`cursor-not-allowed disabled:opacity-50`}
        }
        svg {
          ${tw`ml-1`}
        }
        ${tw`flex items-center px-4 py-2 text-xl transition duration-200 border-2 rounded text-primary border-primary hover:bg-primary hover:text-primary_light`}
      }
    }
  }
`;

export const FieldSetStyles = styled.fieldset`
  &[disabled='true'] {
    ${tw`cursor-not-allowed disabled:opacity-50`}
  }
  ${tw`relative grid gap-4 px-5 py-6 my-3 border border-gray-200 rounded`};
  legend {
    ${tw`absolute p-1 text-lg underline bg-white -top-4 left-4 text-primary`}
  }
`;

export const FieldSetFormStyles = styled.fieldset`
  ${tw`relative grid grid-cols-1 gap-6 px-5 py-6 my-5 border border-gray-200 rounded sm:grid-cols-2`}

  &[disabled='true'] {
    ${tw`cursor-not-allowed disabled:opacity-50`}
  }

  legend {
    ${tw`absolute p-1 text-lg underline bg-white -top-4 left-4 text-primary`}
  }

  label {
    ${tw`text-gray-700 dark:text-gray-200`}
  }
  input {
    ${tw`block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring`}
  }
  .mapleSyrup {
    ${tw`invisible hidden w-0 h-0 scale-100 opacity-0`}
  }
`;
