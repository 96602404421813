import React from 'react';
import MenuItemStyles, {
  MenuItemWrapperStyles,
} from '../../styles/MenuItemStyles.style';
import { GatsbyImage } from 'gatsby-plugin-image';
import formatMoney from '../../utils/formatMoney';
import styled from 'styled-components';
import tw from 'twin.macro';

const NoneProductChosen = styled.div`
  ${tw`text-base text-gray-600`}
  span {
    ${tw`text-base underline text-primary`}
  }
`;

export default function OrderProduct({ orders, products, removeFromOrder }) {
  return (
    <MenuItemWrapperStyles>
      {orders.length > 0 ? (
        orders.map((order, idx) => {
          const product = products.find((product) => product.id === order.id);
          return (
            <MenuItemStyles key={`${order.id}-${idx}`}>
              <GatsbyImage
                image={product.image[0].asset.gatsbyImageData}
                alt={product.image[0].asset.originalFilename}
              />
              <div className="menu-item__content">
                <h3>{product.name}</h3>
                <span>{formatMoney(product.price)}</span>
                <button
                  type="button"
                  className="remove"
                  title={`Remove ${product.name}`}
                  onClick={() => removeFromOrder(idx)}
                >
                  &times;
                </button>
              </div>
            </MenuItemStyles>
          );
        })
      ) : (
        <NoneProductChosen>
          Chưa có sản phẩm nào được chọn! <br />
          Vui lòng chọn mua bằng cách nhấp vào giá ở mục <span>Sản phẩm</span>
        </NoneProductChosen>
      )}
    </MenuItemWrapperStyles>
  );
}
