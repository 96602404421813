import React from 'react';
import useForm from '../utils/useForm';
import Seo from '../components/Seo.component';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SiMinutemailer } from 'react-icons/si';
import formatMoney from '../utils/formatMoney';
import {
  FieldSetWrapperStyles,
  FieldSetFormStyles,
  MenuWrapperStyle,
  FieldSetStyles,
  OrderSectionStyles,
} from '../styles/OrderStyles.style';
import MenuItemStyles from '../styles/MenuItemStyles.style';
import useOrder from '../utils/useOrder';
import OrderProduct from '../components/Order/OrderProduct.component';
import calculateTotal from '../utils/calculateTotal';

function OrdersPage() {
  const {
    products: { nodes: products },
  } = useStaticQuery(graphql`
    query {
      products: allSanityProduct {
        nodes {
          name
          id
          slug {
            current
          }
          price
          image {
            asset {
              url
              originalFilename
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);
  const { values, updateValues } = useForm({
    name: '',
    email: '',
    phone: '',
    address: '',
    mapleSyrup: '',
  });
  const {
    orders,
    addToOrder,
    removeFromOrder,
    error,
    loading,
    message,
    submitOrder,
  } = useOrder({
    products,
    values,
  });
  if (message) {
    return (
      <main className="container py-10 mx-auto text-center min-h-3/4">
        <p className="px-5 py-5 text-base text-black">{message}</p>
        <a
          href="/orders"
          className="px-3 py-2 transition border rounded-lg text-primary border-primary hover:bg-secondary hover:text-white"
        >
          Trở lại trang đặt hàng
        </a>
      </main>
    );
  }
  return (
    <>
      <Seo title={`Đặt hàng`} />
      <OrderSectionStyles>
        <form onSubmit={submitOrder}>
          <FieldSetFormStyles disabled={loading}>
            <legend>Thông tin đặt hàng</legend>
            <div>
              <label htmlFor="name">Tên</label>
              <input
                required
                id="name"
                type="text"
                name="name"
                value={values.name}
                onChange={updateValues}
              />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                required
                id="email"
                type="email"
                name="email"
                value={values.email}
                onChange={updateValues}
              />
            </div>
            <div>
              <label htmlFor="phone">Số điện thoại</label>
              <input
                required
                id="phone"
                type="tel"
                name="phone"
                value={values.phone}
                onChange={updateValues}
              />
            </div>
            <div>
              <label htmlFor="address">Địa chỉ</label>
              <input
                required
                id="address"
                type="text"
                name="address"
                value={values.address}
                onChange={updateValues}
              />
            </div>
            <input
              id="mapleSyrup"
              type="mapleSyrup"
              name="mapleSyrup"
              value={values.mapleSyrup}
              onChange={updateValues}
              className="mapleSyrup"
            />
          </FieldSetFormStyles>
          <FieldSetWrapperStyles>
            <FieldSetStyles disabled={loading} className="menu">
              <legend>Sản phẩm</legend>
              <MenuWrapperStyle>
                {products?.map((product) => (
                  <MenuItemStyles key={product.id}>
                    <GatsbyImage
                      image={product.image[0].asset.gatsbyImageData}
                      alt={product.image[0].asset.originalFilename}
                    />
                    <div className="menu-item__content">
                      <h3>{product.name}</h3>
                      <button
                        type="button"
                        onClick={() => addToOrder({ id: product.id })}
                      >
                        {formatMoney(product.price)}
                      </button>
                    </div>
                  </MenuItemStyles>
                ))}
              </MenuWrapperStyle>
            </FieldSetStyles>
            <FieldSetStyles disabled={loading} className="order">
              <legend>Đặt hàng</legend>
              <OrderProduct
                orders={orders}
                removeFromOrder={removeFromOrder}
                products={products}
              />
            </FieldSetStyles>
          </FieldSetWrapperStyles>
          <FieldSetWrapperStyles className="bill">
            <h3>
              Tổng hoá đơn: <span>{calculateTotal(orders, products)}</span>
            </h3>
            {error && (
              <div>
                {<p className="pt-1 pb-4 text-xl text-red-600">Lỗi: {error}</p>}
              </div>
            )}
            <div>
              <button type="submit" disabled={loading}>
                {loading ? (
                  <>
                    Đang gửi email đặt hàng
                    <SiMinutemailer />
                  </>
                ) : (
                  'Đặt hàng ngay!'
                )}
              </button>
            </div>
          </FieldSetWrapperStyles>
        </form>
      </OrderSectionStyles>
    </>
  );
}

export default OrdersPage;

// export const query = graphql`
//   query {
//     products: allSanityProduct {
//       nodes {
//         name
//         id
//         slug {
//           current
//         }
//         image {
//           asset {
//             originalFilename
//             gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
//           }
//         }
//       }
//     }
//   }
// `;
