import { useState, useContext } from 'react';
import OrderContext from '../components/Order/OrderContext.component';
import attachNamesAndPrices from './attachNamesAndPrices';
import calculateTotal from './calculateTotal';
export default function useOrder({ products, values }) {
  // 1. create some state to hold orders
  const [orders, setOrder] = useContext(OrderContext);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  // 2. make a function to add things to order
  function addToOrder(orderedProducts) {
    setOrder([...orders, orderedProducts]);
  }
  // 3. make a function to remove things from order
  function removeFromOrder(idx) {
    setOrder([
      // everything before the item we want to remove
      ...orders.slice(0, idx),
      // everything after the item we want to remove
      ...orders.slice(idx + 1),
    ]);
  }

  // function that is run when someone is submit the form
  async function submitOrder(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setMessage(null);

    //gather all the data
    const body = {
      orders: attachNamesAndPrices(orders, products),
      total: calculateTotal(orders, products),
      name: values.name,
      email: values.email,
      phone: values.phone,
      address: values.address,
      mapleSyrup: values.mapleSyrup,
    };

    // 4. send this data to a serverless function when they checkout
    const res = await fetch(
      `${process.env.GATSBY_SERVERLESS_BASE}/placeOrder`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        //parse object to string
        body: JSON.stringify(body),
      }
    );
    // parse res string to object from server
    const text = JSON.parse(await res.text());

    if (res.status >= 400 && res.status < 600) {
      setLoading(false);
      setError(text.message);
    } else {
      setLoading(false);
      setMessage(
        `Đơn hàng đã được ghi nhận thành công! Sẽ có nhân viên liên hệ với bạn trong thời gian sớm nhất!!!`
      );
    }
  }

  return {
    orders,
    addToOrder,
    removeFromOrder,
    error,
    loading,
    message,
    submitOrder,
  };
}
